import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./feature.scss"

const Feature = ({ size, layout, title, excerpt, url, imgData, imgAlt }) => (
    <div className={`fea fea--${size} fea--${layout}`}>
        <Link className="fea__img" to={url}>
            <Img
                fluid={imgData}
                alt={imgAlt}
            />
        </Link>
        <Link className="fea__hed" to={url}>
            <h3>{title}</h3>
        </Link>
    </div>
)

export default Feature
