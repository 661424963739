import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Feature from "../components/feature/feature"
import HomeSection from "../components/homeSection/homeSection"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/home.scss"

export default function Home({ data }) {

  let numsArray = [];
  let ranNums;

  const createNumsArray = () => {
    for (let x = 1; x < 51; x++){
      numsArray.push(x);
    }
    return numsArray;
  };

  function shuffle(array) {
    var i = array.length,
        j = 0,
        temp;
    while (i--) {
        j = Math.floor(Math.random() * (i+1));
        // swap randomly chosen element with current element
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
  }

  createNumsArray();

  const shuffleRanNums = () => {
    ranNums = shuffle(numsArray);
  };

  return (
    <Layout includeHeader="no" customClass="layout--home">

      <SEO 
        ogImage={data.ogImage.publicURL}
      />

      <Header 
        siteTitle={data.siteTitleQuery.siteMetadata?.title || `Title`}
        headerDescription="I’ve been crafting interactive experiences for over 15 years. I’m a visual designer and user experience designer specializing in editorial and journalism. I’ve spent my career working in public media on projects that inspire change and hold power to account. Thank you for spending some time with my work."
      />

      <div className="home__grid">
        <HomeSection
          title="Editorial Design"
          timeSpan="2015–present"
        />

        <div className="home__grid--feas">
        {data.featuredEditorial.edges.map(({ node }) => (
            <Feature
              size="large"
              layout={node.frontmatter.feaLayout}
              title={node.frontmatter.title}
              excerpt={node.excerpt}
              url={node.fields.slug}
              imgData={node.frontmatter.feaImage.childImageSharp.fluid}
              imgAlt={(node.frontmatter.homeAlt ? node.frontmatter.homeAlt : node.frontmatter.heroAlt)}
            />
        ))}

        {data.editorial.edges.map(({ node }) => (
          <Feature
            size="small"
            layout={node.frontmatter.feaLayout}
            title={node.frontmatter.title}
            excerpt={node.excerpt}
            url={node.fields.slug}
            imgData={node.frontmatter.feaImage.childImageSharp.fluid}
            imgAlt={(node.frontmatter.homeAlt ? node.frontmatter.homeAlt : node.frontmatter.heroAlt)}
          />
        ))}
        </div>
      </div>

      <div className="home__grid">
      <HomeSection
        title="Design for Kids"
        timeSpan="2006–2015"
      />

        <div className="home__grid--feas">
        {data.kids.edges.map(({ node }) => (
          <Feature
            size="small"
            layout={node.frontmatter.feaLayout}
            title={node.frontmatter.title}
            excerpt={node.excerpt}
            url={node.fields.slug}
            imgData={node.frontmatter.feaImage.childImageSharp.fluid}
            imgAlt={(node.frontmatter.homeAlt ? node.frontmatter.homeAlt : node.frontmatter.heroAlt)}
          />
        ))}
        </div>
      </div>

      <div className="home__grid">
        <HomeSection
          title="Design Experiments"
          timeSpan="Ongoing"
        />

        <p className="home__section-text"><a href="https://www.instagram.com/dj_nol/" target="_blank">Follow me on instagram</a> where I indulge in various design experiments: type compositions, collages, album cover designs or whatever new style I’m exploring at the moment.</p>

        <div className="intro">
          {shuffleRanNums()}
          {data.introImages.edges.map(({ node }, i) => (
            <div key={i} className={`intro__img intro__img--${ranNums[i]}`}>
              <Img
                fluid={node.childImageSharp.fluid}
              />
            </div>
          ))}
        </div>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    allProjects: allMdx(
      sort: {
        fields: [frontmatter___date], order: DESC
      }
    ){
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
              relativeDirectory
              relativePath
            }
          }
          fields {
            slug
          }
          excerpt
          id
        }
      }
    }
    featuredEditorial: allMdx(
      filter: {
        frontmatter: { featured: { eq: "editorial" } }
      }
      sort: {
        fields: [frontmatter___date], order: DESC
      }
    ){
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            heroAlt
            homeAlt
            feaImage {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
              relativeDirectory
              relativePath
            }
            feaLayout
          }
          fields {
            slug
          }
          excerpt
          id
        }
      }
    }
    editorial: allMdx(
      filter: {
        frontmatter: { type: { eq: "editorial" }, featured: { ne: "editorial" } }
      }
      sort: {
        fields: [frontmatter___date], order: DESC
      }
    ){
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            heroAlt
            homeAlt
            feaImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              relativeDirectory
              relativePath
            }
            feaLayout
          }
          fields {
            slug
          }
          excerpt
          id
        }
      }
    }
    kids: allMdx(
      filter: {
        frontmatter: { type: { eq: "kids" } }
      }
      sort: {
        fields: [frontmatter___date], order: DESC
      }
    ){
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            heroAlt
            homeAlt
            feaImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              relativeDirectory
              relativePath
            }
            feaLayout
          }
          fields {
            slug
          }
          excerpt
          id
        }
      }
    }
    introImages: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "images/intro-images"}}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    ogImage: file(relativePath: {eq: "images/dan-nolan-og.png"}) {
      publicURL
    }
    siteTitleQuery: site {
      siteMetadata {
        title
        subTitle
        description
      }
    }
  }
`