import React from "react"
import "./homeSection.scss"

const HomeSection = ({ customClass, title, timeSpan }) => (
    <div className={`home-section home-section--${customClass}`}>
        <div className="home-section__hgroup">
            <h2 className="home-section__hed">{title}</h2>
            <p className="home-section__timespan">{timeSpan}</p>
        </div>
    </div>
)

export default HomeSection